import { IconSparkle } from '@leland-dev/leland-ui-library';
import React, { useState } from 'react';

import { wasMutationSuccessful } from '../../utils/mutations';
import SwitchInput from '../inputs/SwitchInput';

import {
  useGenerateSessionSummaryUpdateUserMutation,
  useGenerateSessionSummaryUserQuery,
} from './__generated-gql-types__/GenerateSessionSummary.generated';

interface SwitchSectionProps {
  mainHeading: string;
  label: string;
  subLabel: string;
  showDivider?: boolean;
}

const GenerateSessionSummary: React.FC<SwitchSectionProps> = ({
  mainHeading,
  label,
  subLabel,
  showDivider = true,
}) => {
  const { data: user } = useGenerateSessionSummaryUserQuery();

  const [optOutTranscriptionValue, setOptOutTranscriptionValue] =
    useState<boolean>(user?.user?.optOutTranscription ?? false);

  const [updateUser] = useGenerateSessionSummaryUpdateUserMutation();

  const handleOptOutToggle = async () => {
    try {
      const res = await updateUser({
        variables: {
          user: {
            optOutTranscription: !optOutTranscriptionValue,
          },
        },
      });

      if (wasMutationSuccessful(res)) {
        setOptOutTranscriptionValue((prevState) => !prevState);
      } else {
        console.warn(res.errors);
        throw new Error('Failed to update user');
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <div className="mt-6 w-full">
      {showDivider ? (
        <div className="flex w-full flex-col space-y-6 border-t border-leland-gray-stroke py-4"></div>
      ) : null}
      <div className="flex items-start">
        <div className="mb-[24px] flex w-[90%] flex-col space-y-2">
          <div className="flex items-center gap-2">
            <IconSparkle className="size-4.5" />
            <div className="flex w-full items-center justify-between space-x-2 text-lg font-medium leading-6 text-leland-gray-dark">
              <h3>{mainHeading}</h3>
            </div>
          </div>

          <p className="text-lg leading-6 text-leland-gray-light">{label}</p>

          <p className="text-lg leading-6 text-[#9B9B9B]">{subLabel}</p>
        </div>
        <div className="flex w-[10%] justify-end">
          <SwitchInput
            className=""
            label=""
            isChecked={optOutTranscriptionValue}
            onToggle={handleOptOutToggle}
          />
        </div>
      </div>
    </div>
  );
};

export default GenerateSessionSummary;
