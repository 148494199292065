// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateSessionSummaryUserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  optOutTranscription: boolean;
};

export type GenerateSessionSummaryUpdateUserMutationVariables = Types.Exact<{
  user?: Types.InputMaybe<Types.UserInput>;
}>;

export type GenerateSessionSummaryUpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    optOutTranscription: boolean;
  };
};

export type GenerateSessionSummaryUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GenerateSessionSummaryUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    optOutTranscription: boolean;
  } | null;
};

export const GenerateSessionSummaryUserFragmentDoc = gql`
  fragment GenerateSessionSummary_User on User {
    id
    firstName
    lastName
    optOutTranscription
  }
`;
export const GenerateSessionSummaryUpdateUserDocument = gql`
  mutation GenerateSessionSummary_UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      ...GenerateSessionSummary_User
    }
  }
  ${GenerateSessionSummaryUserFragmentDoc}
`;
export type GenerateSessionSummaryUpdateUserMutationFn =
  Apollo.MutationFunction<
    GenerateSessionSummaryUpdateUserMutation,
    GenerateSessionSummaryUpdateUserMutationVariables
  >;

/**
 * __useGenerateSessionSummaryUpdateUserMutation__
 *
 * To run a mutation, you first call `useGenerateSessionSummaryUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSessionSummaryUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSessionSummaryUpdateUserMutation, { data, loading, error }] = useGenerateSessionSummaryUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGenerateSessionSummaryUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSessionSummaryUpdateUserMutation,
    GenerateSessionSummaryUpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateSessionSummaryUpdateUserMutation,
    GenerateSessionSummaryUpdateUserMutationVariables
  >(GenerateSessionSummaryUpdateUserDocument, options);
}
export type GenerateSessionSummaryUpdateUserMutationHookResult = ReturnType<
  typeof useGenerateSessionSummaryUpdateUserMutation
>;
export type GenerateSessionSummaryUpdateUserMutationResult =
  Apollo.MutationResult<GenerateSessionSummaryUpdateUserMutation>;
export type GenerateSessionSummaryUpdateUserMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateSessionSummaryUpdateUserMutation,
    GenerateSessionSummaryUpdateUserMutationVariables
  >;
export const GenerateSessionSummaryUserDocument = gql`
  query GenerateSessionSummary_User {
    user {
      id
      firstName
      lastName
      optOutTranscription
    }
  }
`;

/**
 * __useGenerateSessionSummaryUserQuery__
 *
 * To run a query within a React component, call `useGenerateSessionSummaryUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateSessionSummaryUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateSessionSummaryUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateSessionSummaryUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GenerateSessionSummaryUserQuery,
    GenerateSessionSummaryUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GenerateSessionSummaryUserQuery,
    GenerateSessionSummaryUserQueryVariables
  >(GenerateSessionSummaryUserDocument, options);
}
export function useGenerateSessionSummaryUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateSessionSummaryUserQuery,
    GenerateSessionSummaryUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GenerateSessionSummaryUserQuery,
    GenerateSessionSummaryUserQueryVariables
  >(GenerateSessionSummaryUserDocument, options);
}
export function useGenerateSessionSummaryUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerateSessionSummaryUserQuery,
    GenerateSessionSummaryUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GenerateSessionSummaryUserQuery,
    GenerateSessionSummaryUserQueryVariables
  >(GenerateSessionSummaryUserDocument, options);
}
export type GenerateSessionSummaryUserQueryHookResult = ReturnType<
  typeof useGenerateSessionSummaryUserQuery
>;
export type GenerateSessionSummaryUserLazyQueryHookResult = ReturnType<
  typeof useGenerateSessionSummaryUserLazyQuery
>;
export type GenerateSessionSummaryUserSuspenseQueryHookResult = ReturnType<
  typeof useGenerateSessionSummaryUserSuspenseQuery
>;
export type GenerateSessionSummaryUserQueryResult = Apollo.QueryResult<
  GenerateSessionSummaryUserQuery,
  GenerateSessionSummaryUserQueryVariables
>;
