import {
  Button,
  ButtonColor,
  ButtonWidth,
  Modal,
  ModalContent,
  type ModalHook,
  ModalSize,
  useControlledModalState,
} from '@leland-dev/leland-ui-library';
import Image from 'next/image';
import React, { type FC, useCallback } from 'react';

import SummaryModalImage from '../../assets/images/session-summary/summaryModalImage.png';
import {
  FeatureInteraction,
  useFeatureInteractions,
} from '../../context/FeatureInteractionsContext';
import GenerateSessionSummary from '../common/GenerateSessionSummary';

interface SessionSummaryModalHookProps {
  onClose: () => void;
}

interface SessionSummaryModalProps extends SessionSummaryModalHookProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SessionSummaryModal: FC<SessionSummaryModalProps> = ({
  open,
  setOpen,
  onClose,
}) => {
  const { isFeatureInteracted, logFeatureInteraction } =
    useFeatureInteractions();

  const onOpenChange = useCallback(
    (value: boolean) => {
      setOpen(value);
      if (!value) {
        onClose();
      }
    },
    [setOpen, onClose],
  );

  return (
    <Modal open={open} onOpenChange={onOpenChange}>
      <ModalContent size={ModalSize.SMALL}>
        <h3 className="ml-6 mt-3 text-xl font-medium leading-7">
          Session Summaries
        </h3>
        <p className="mx-6 mb-3 text-lg text-leland-gray-light">
          Leland can now generate text summaries of your sessions. This makes it
          easy to revisit important concepts and stay aligned on your action
          items.
        </p>
        <Image
          src={SummaryModalImage}
          className="mx-auto h-auto w-[90%]"
          alt=""
        />
        <div className="mt-3 px-6">
          <GenerateSessionSummary
            mainHeading="Generate session summaries"
            label="Leland can generate text summaries of your sessions, making it easy to revisit important concepts and stay aligned on your action items."
            subLabel="In order to generate session summaries, both attendees must have the feature enabled."
            showDivider={false}
          />
        </div>
        <div className="w-full px-6">
          <Button
            label="Looks good"
            buttonColor={ButtonColor.PRIMARY}
            width={ButtonWidth.FULL}
            onClick={async () => {
              if (
                !isFeatureInteracted(FeatureInteraction.SESSION_SUMMARY_MODAL)
              ) {
                await logFeatureInteraction(
                  FeatureInteraction.SESSION_SUMMARY_MODAL,
                );
                setOpen(false);
              }
            }}
          />
        </div>

        <p className="px-6 py-1 text-center text-sm text-leland-gray-extra-light">
          You can edit these permissions in your account settings page.
        </p>
      </ModalContent>
    </Modal>
  );
};

export const useSessionSummaryModal: ModalHook<
  SessionSummaryModalHookProps
> = () => {
  const [open, setOpen] = useControlledModalState();
  const SessionSummaryInfoModal: FC<SessionSummaryModalHookProps> = (props) => {
    return open ? (
      <SessionSummaryModal {...props} open={open} setOpen={setOpen} />
    ) : null;
  };
  return { open, setOpen, Modal: SessionSummaryInfoModal };
};
